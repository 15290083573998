/**
 * Created by Tim Osadchiy on 27/05/2017.
 */

"use strict";

module.exports = function (app) {
    app.controller("ImageGalleryGuided", [function () {

    }]);
};
