/**
 * Created by Tim Osadchiy on 15/02/2017.
 */

"use strict";

module.exports = function (app) {
    app.controller("SurveyFeedbackController", ["$scope", controllerFun]);
};

function controllerFun($scope) {

}
