/**
 * Created by Tim Osadchiy on 09/11/2016.
 */

'use strict';

module.exports = function (app) {
    app.controller('MainController', ['$scope', controllerFun]);
};

function controllerFun($scope) {

}

