/**
 * Created by Tim Osadchiy on 23/04/2017.
 */

"use strict";

module.exports = function (app) {
    app.controller('WelcomeController', ['$scope', controllerFun]);
};

function controllerFun($scope) {

}
