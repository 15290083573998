/**
 * Created by Tim Osadchiy on 30/09/2017.
 */

"use strict";

module.exports = function (app) {
    app.controller("ImageGalleryGuidedItem", [function () {

    }]);
};